<template>
    <div class="layout">
        <header v-if="showNavBar">
            <Navbar />
        </header>
        <header v-if="showHeader" class="">
            <!-- Your header content goes here -->
            <HeroPage />
        </header>
        <main v-if="showMain1">
            <FeaturesPage />

        </main>
        <main>
            <!-- Your main content goes here -->
            <router-view></router-view>
        </main>
        <footer>
            <div class="footer-contact-us" @click="toggleDropdown">
                Contact Us
                <div v-show="isOpen" class="dropup-menu">
                    <ul class="ContactUs__menu">
                        <li class="ContactUs__menu--item top">
                            <a href="/contact-us" class="ContactUs__menu--link violet" data-close-target="contactUsMenu"
                                rel="nofollow noopener external">
                                Send us an Email
                                <i class="bi bi-chat-dots"></i>
                            </a>
                        </li>
                        <li class="ContactUs__menu--item">
                            <a href="" class="ContactUs__menu--link violet" data-close-target="contactUsMenu"
                                rel="nofollow noopener external">
                                Messenger
                                <i class="bi bi-chat-dots"></i>
                            </a>
                        </li>
                        <li class="ContactUs__menu--item whatsapp" @click="selectedOption = 'whatsapp'">
                            <WhatsAppButton phoneNumber="254723457189" message="Hello, I need some help!" />
                        </li>

                    </ul>
                </div>
                {{ selectedOption }}
                <WhatsAppEmbed phoneNumber="254723457189" message="Hello, I need some help!"
                    v-if="selectedOption === 'whatsapp'" />


            </div>
            <p>&copy; 2023 Procus Limited</p>
        </footer>
    </div>
</template>

<script>
    import HeroPage from '@/components/HeroPage.vue';
    import Navbar from '@/components/NavBar.vue';
    import { useRoute } from 'vue-router';
    import { watch, ref } from 'vue';
    import FeaturesPage from '@/views/Features/FeaturesPage.vue'
    import WhatsAppButton from '@/views/profile/WhatsAppButton.vue';
    import WhatsAppEmbed from '@/views/profile/WhatsAppEmbed.vue';



    export default {
        name: 'LayoutFile',
        components: {
            HeroPage,
            Navbar,
            FeaturesPage,
            WhatsAppButton,
            WhatsAppEmbed,
        },

        setup() {
            const route = useRoute();
            const showHeader = ref("");
            const showNavBar = ref(true);
            const selectedOption = ref('');
            const showMain1 = ref(false);



            watch(
                () => route.meta,
                (meta) => {
                    showHeader.value = meta.showHeader || false;
                    showNavBar.value = meta.showNavBar || false;
                    showMain1.value = meta.showMain1 || false;
                },
                { immediate: true }
            );

            const isOpen = ref(false);

            const toggleDropdown = () => {
                isOpen.value = !isOpen.value;
                console.log(isOpen.value);
            };

            const handleClickOutside = (event) => {
                if (isOpen.value && !event.target.closest('.footer-contact-us')) {
                    isOpen.value = false;
                }
            };

            // Close the drop-up when clicking outside of it
            window.addEventListener('click', handleClickOutside);

            // Remove the event listener when the component is unmounted
            const cleanup = () => {
                window.removeEventListener('click', handleClickOutside);
            };


            return {
                showHeader,
                showNavBar,
                isOpen,
                toggleDropdown,
                cleanup,
                selectedOption,
                showMain1,


            }
        }

    };
</script>

<style scoped>

    /* Your styling goes here */
    .layout {
        display: flex;
        flex-direction: column;

    }

    body {
        margin: 0;
        padding: 0;
    }

    header {
        color: #fff;
        margin: 0;
        padding: 0 1rem;
        margin-bottom: 3rem;
        text-align: center;
        /* background-color: #1e272e; */
        position: relative;

    }

    main {
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 6rem);
    }

    footer {
        background-color: #2c3e50;
        color: #fff;
        padding: 1rem;
        text-align: center;
        position: fixed;
        bottom: 0;
        width: 100%;
    }
</style>
